import React, { useState } from 'react';
import { Container, TextField, Checkbox, FormControlLabel, Button } from '@mui/material';
import Header2 from '../components/Header2';
import Footer from '../components/Footer';
import '../styles/pages_style/reservationPage.scss';
import CartItem from '../components/newComponents/CartItem';
import Alert from '../components/Alert';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ScrollToTop from "../components/ScrollTop";
import { useTravel } from '../context/TravelCartContext';

const ReservationPage = () => {
  document.title = "Page de Reservation";
  const navigate = useNavigate();
  const {travelResumeInLocal} = useTravel();

  const [open, setOpen] = useState(false);
  const [openSucces, setOpenSucces] = useState(false);
  let url2 = "https://cocobino-backend.onrender.com/demandChecking";
  //let url1 = "http://localhost:5000/demandChecking"
  // Initialisation des hooks de formulaire
  const { register, handleSubmit, formState: { errors } } = useForm();

  function handleReserverVoyageClick(data) {
    if (travelResumeInLocal.itemList.length !== 0 && data !== null) {
      fetch(url2, {

        method: "POST",
        headers: {
          'Content-type': "application/json"
        },
        body: JSON.stringify({
          clientInfos: data,
          clientTravel: travelResumeInLocal
        })
      })
        .then(res => res.json())
        .then((data) => {
          console.log(data)
          setOpenSucces(true);
          navigate("/thankyou");
        })
        .catch(err => console.log(err));
    } else {
      setOpen(true);
    }
  }

  const onSubmit = (data) => {
    handleReserverVoyageClick(data);
  };

  return (
    <>
      <Header2 />
      <ScrollToTop />
      <Alert message={"une erreur s'est produite"} openState={open} setOpenFunction={setOpen} />
      <Alert message={"Email envoyé avec succées"} openState={openSucces} setOpenFunction={setOpenSucces} />
      <Container className="app-container">
        <button onClick={() => navigate(-1)} style={{ backgroundColor: "black", color: "white", padding: "6px", borderStyle: "none", borderRadius: "6px" }}>revenir en arrière</button>
        <h1 >Réservez votre voyage</h1>
        <p variant="body2" className="description">
        Cher client, nous vous prions de vérifier attentivement vos données. Cela est crucial pour prévenir tout problème éventuel. Merci pour votre attention !
        </p>
        <div className='main'>
          <form className='main_inputForm' onSubmit={handleSubmit(onSubmit)}>
            <TextField
              label="Nom"
              variant="outlined"
              fullWidth
              margin="normal"
              {...register("nom", { required: "Ce champ est requis" })}
              error={!!errors.nom}
              helperText={errors.nom ? errors.nom.message : ""}
            />

            <TextField
              label="Nom de famille"
              variant="outlined"
              fullWidth
              margin="normal"
              {...register("nomDeFamille", { required: "Ce champ est requis" })}
              error={!!errors.nomDeFamille}
              helperText={errors.nomDeFamille ? errors.nomDeFamille.message : ""}
            />

            <TextField
              label="@email"
              variant="outlined"
              fullWidth
              margin="normal"
              {...register("email", {
                required: "Ce champ est requis",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Adresse email invalide"
                }
              })}
              error={!!errors.email}
              helperText={errors.email ? errors.email.message : ""}
            />

            <TextField
              label="Numéro de téléphone"
              variant="outlined"
              fullWidth
              margin="normal"
              {...register("telephone", {
                required: "Ce champ est requis",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Numéro de téléphone invalide"
                }
              })}
              error={!!errors.telephone}
              helperText={errors.telephone ? errors.telephone.message : ""}
            />

            <TextField
              label="n'hésitez pas à nous transmettre un message"
              variant="outlined"
              fullWidth
              margin="normal"
              multiline
              rows={4}
              {...register("message")}  // Ce champ est optionnel
            />

            <FormControlLabel
              control={<Checkbox {...register("conditions", { required: "Vous devez accepter les conditions de vente" })} color="success" />}
              label={<span>Vous avez lu et compris nos <p onClick={()=>(window.open('/condition_vente'))} id='termOfSell_text'>conditions de vente</p></span>}
            />
            {errors.conditions && <p style={{ color: 'red' }}>{errors.conditions.message}</p>}
          </form>
          <div className='main_itemList'>
            <h2>Résumé de votre panier :</h2>
            <div className='main_itemList_itemSlide'>
              {travelResumeInLocal !== null ? (
                travelResumeInLocal.itemList.map((item, key) => {
                  return (
                    <CartItem
                      sourceImg={item.imgUrl}
                      nombreChildren={item.numberOfChildren}
                      type={item.type}
                      nombreDePersonnes={item.numberOfPersons}
                      prix={item.totalPrice}
                      title={item.title}
                      isOnReservationPage={true}
                      key={key}
                    />
                  )
                })
              ) : (
                <p>votre panier est vide ! veuillez retourner vers la page prédénte</p>
              )}
            </div>
            <div style={{ marginTop: "10px" }} >
              <p>total : <strong>{travelResumeInLocal.totalPrice} €</strong></p>
            </div>
            <div style={{ marginTop: "10px" }} >
              <p>arrivé : <strong>{travelResumeInLocal.dateArrive}</strong> dépard <strong>{travelResumeInLocal.dateDepard}</strong></p>
            </div>
          </div>
        </div>
        <Button type="submit" variant="contained" color="primary" fullWidth className="submit-btn" onClick={handleSubmit(onSubmit)}>
          Obtenir votre devis
        </Button>

      </Container>
      <Footer />
    </>
  );
};

export default ReservationPage;