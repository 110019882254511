import React from "react";
import { Button, Typography } from "@mui/material";
//import { endOfWeek, differenceInDays } from "date-fns";
import '../../styles/components_style/promotionBanner.scss';
import { useNavigate } from "react-router-dom";

function PromotionBanner({title,message,displayBtn,btnLink}) {

    const daysLeft = 6;
    const navigate = useNavigate();
    function handleClick() {
        navigate(btnLink);
    }

    /*useEffect(() => {
      const updateDaysLeft = () => {
        // Calculer le nombre de jours restants jusqu'à dimanche minuit
        const now = new Date();
        const nextSundayMidnight = endOfWeek(now, { weekStartsOn: 0 });
        const remainingDays = differenceInDays(nextSundayMidnight, now);
        setDaysLeft(remainingDays);
      };
  
      updateDaysLeft(); // Initialiser le calcul
      const interval = setInterval(updateDaysLeft, 86400000); // Met à jour tous les jours
  
      return () => clearInterval(interval); // Nettoyage
    }, []);*/

    return (
        <div className="banner">
            <Typography
                variant="h6"
                sx={{
                    fontWeight: "bold",
                    lineHeight: "1.2",
                }}
            >
                {title}
            </Typography>
            <Typography
                variant="body2"
                sx={{
                    marginY: 0.5,
                    lineHeight: "1.1",
                }}
            >
                {message}

            </Typography>
            <Typography>
                <strong>Durée limitée : {daysLeft} jours restants !</strong>
            </Typography>
            {displayBtn === true ? (
                            <Button
                variant="contained"
                color="secondary"
                sx={{
                    marginTop: 1,
                    padding: "5px 15px",
                    fontSize: "0.8rem",
                    backgroundColor: "#d4145a",
                    color: "white",
                    "&:hover": { backgroundColor: "#a20e47" },
                }}
                onClick={() => handleClick()}
            >
                🌹 Je découvre l’offre 🌹
            </Button>
            ) : null}

        </div>
    );
}

export default PromotionBanner;
