import React from 'react';
import App from './App';
import "./styles/index.scss";
import { hydrate, render } from "react-dom";
import { BrowserRouter as Router } from 'react-router-dom';

const APP = (
  <Router>
    <App />
  </Router>
);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(APP, rootElement);
} else {
  render(APP, rootElement);
}

