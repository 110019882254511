import { React, useMemo, useState } from 'react';
import SliderCarroussel from './SliderCarroussel';
import Button from '@mui/material/Button';
import '../../styles/components_style/activityBubble.scss';
import IncrementButtonGroup from './IncrementButtonGroup';
import ButtonSimple from '../ButtonSimple';
import { useTravel } from '../../context/TravelCartContext';

const ActivityBubble = ({ activityImgUrlArr, activityTitle, activityAdvantageArr, activityPriceAdulte, activityDescription, activityLocation, activityType }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => { setIsModalOpen(true); }
    const closeModal = () => { setIsModalOpen(false); }
    const { addActivityToShoppingCart, getPersonalisedAlert } = useTravel();

    const [readRoomDescription, setReadRoomDescription] = useState(false);

    const displayRoomDescription = () => {
        if (readRoomDescription === false) {
            setReadRoomDescription(true);
        } else {
            setReadRoomDescription(false);
        }
    }

    const [numberOfPersonA, setNumberOfPersonA] = useState(0);

    const totalActivityPrice = useMemo(() => {
        return (numberOfPersonA * activityPriceAdulte);
    }, [numberOfPersonA, activityPriceAdulte]);


    function handleClickAddToCart() {
        if (numberOfPersonA !== 0) {
            if(activityType === 'activity') {
                addActivityToShoppingCart(numberOfPersonA, activityTitle, 0, activityImgUrlArr[0], "activity", activityLocation);
                closeModal();
            } else {
                addActivityToShoppingCart(numberOfPersonA, activityTitle, totalActivityPrice, activityImgUrlArr[0], "activity", activityLocation);
                closeModal();
            }
            
        } else {
            getPersonalisedAlert("veuillez choissir le nombre de personnes");
        }
    }

    function getSectionName() {
        switch (activityType) {
            case "activity_coco":
                return (
                    <div className="activity_roomContainer_elmntCenter_reservation_price">
                        <p> <strong>activité coco</strong></p>
                    </div>
                )
            case "activity":
                return (
                    <div className="activity_roomContainer_elmntCenter_reservation_price">
                        <p> <strong>bon de réduction</strong></p>
                    </div>
                )
            default: return null
        }
    }

    return (
        <>
            <div className='activity_containerWrapper'>
                <div className="activity_roomContainer">
                    <div className="activity_roomContainer_elmntCenter">
                        <div className="activity_roomContainer_elmntCenter_imgContainer">
                            <img className='activity_roomContainer_elmntCenter_imgContainer_img' src={activityImgUrlArr[0]} alt="" loading='lazy' />
                        </div>
                        <div className="activity_roomContainer_elmntCenter_reservation">
                            <div className="activity_roomContainer_elmntCenter_reservation_title">
                                <h3 className='roomtitle_activity'>{activityTitle}</h3>
                            </div>
                            {getSectionName()}
                            <div className="activity_roomContainer_elmntCenter_reservation_calltoActionBtn">
                                <div className="buttonWrapper">
                                    <Button variant="contained" size='small' onClick={openModal} style={{ 'backgroundColor': 'black', 'fontSize': '11px', 'boxShadow': 'none', width: "100%" }}> <p>voir l'activité</p> </Button>
                                </div>
                            </div>
                        </div>
                        <div className='activity_roomContainer_elmntCenter_advantageContainer'>
                            <div className='activity_roomContainer_elmntCenter_advantageContainer_topSetting'>
                                {activityAdvantageArr.map((string, key) => {
                                    return (
                                        <div className='activity_roomContainer_elmntCenter_advantageContainer_elmnt' key={key}>
                                            <p className='activity_roomContainer_elmntCenter_advantageContainer_elmnt_p' key={key}>{string}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isModalOpen && (
                <div className={`activity_roomContainer_screenWrapper`}>

                    <div className="activity_roomContainer_popupRoom">

                        <div className="buttonContainer">
                            <ButtonSimple label={"fermer"} number={1} onClickFunction={closeModal} />
                        </div>
                        <div className='imageSliderWrapper'>
                            <SliderCarroussel imageList={activityImgUrlArr} />
                        </div>
                        <div className="activity_popupRoom_titleWrapper">
                            <h3>{activityTitle}</h3>
                        </div>
                        <div className="activity_popupRoom_addresse">
                            <div className='activity_popupRoom_addresse_imgContainer'>
                                <img className='travelInformation_details_location_iconContainer_img' src="https://res.cloudinary.com/dpistjmej/image/upload/v1721852254/location-svgrepo-com_1_xzjk8i.svg" alt=""
                                    style={{
                                        width: "90%",
                                        height: "100%"
                                    }} />
                            </div>
                            <strong><p>{activityLocation}</p></strong>
                        </div>

                        {activityType !== 'activity' ? (
                            <div style={{ marginTop: "10px" }}>
                                <p><strong>{activityPriceAdulte} €</strong> / adulte</p>
                            </div>
                        ) : (
                            <p style={{ marginTop: '7px' }}>
                                la réservation est gratuite !
                            </p>
                        )}
                        <p
                            onClick={displayRoomDescription}
                            style={{ cursor: "pointer", marginTop: "10px" }}
                        > <strong>{readRoomDescription === false ? ('lire la descripion') : ('fermer la description')}</strong>
                        </p>
                        <div className="activity_popupRoom_descriptionContainer">
                            {readRoomDescription && (
                                <p>{activityDescription}</p>
                            )}
                        </div>

                        <div className="roomList_popupRoom_numberOfPersonContainer">
                            <p>nombre d'adultes : </p>
                            <IncrementButtonGroup
                                setResult={setNumberOfPersonA}
                                maxNumberOfPerson={4}
                            />
                        </div>
                        {activityType === 'activity' ? (

                            <div style={{ marginTop: '5px', color: 'rgba(128, 15, 47, 0.867)' }}>
                                <p>Réservez ici et optenez 10% de réduction sur l'addition</p>
                            </div>


                        ) : (null)}
                        <div className="roomList_popupRoom_priceAndBtnBlock">
                            {activityType !== 'activity' ? (
                                <div className="roomList_popupRoom_priceAndBtnBlock_priceContainer">
                                    <p className='roomList_popupRoom_priceAndBtnBlock_priceContainer_price' style={{ color: 'rgba(128, 15, 47, 0.867)' }}>{totalActivityPrice} €</p>
                                    <div className='roomList_popupRoom_priceAndBtnBlock_priceContainer_text'>
                                        <p>prix total de l'activité</p>
                                    </div>
                                </div>
                            ) : (
                                null
                            )}

                            <div className='roomList_popupRoom_priceAndBtnBlock_btnWrapper'>
                                <Button variant="contained" style={{
                                    'backgroundColor': 'black',
                                    'fontSize': '12px',
                                    'width': '90%'
                                }}
                                    onClick={handleClickAddToCart}
                                >Ajouter l'activité</Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ActivityBubble;