import React, { useState } from 'react';
import '../styles/pages_style/Boutique.scss';
import BoxDeVoyage from '../components/BoxDeVoyage';
import Footer from '../components/Footer';
import Header2 from "../components/Header2";

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import ScrollToTop from '../components/ScrollTop';
import COCOBINO_HOTELS from "../local_db/db.json";
import PromotionBanner from '../components/newComponents/PromotionBanner';

const Boutique = () => {
    document.title = 'Découvrez les Meilleurs Hôtels Insolites au Luxembourg';

    const [open, setOpen] = useState(false);

    /*const handleClick = () => {
        setOpen(true); // Ouvrir l'alerte
    };*/

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false); // Fermer l'alerte
    };

    const messageBanner = (
        <>
            Cette <strong>offre</strong> s'aplique sur tout nos voyages et établissement partenaires
        </>
    );

    return (
        <div>

            <meta name="description" content="Découvrez les meilleures options d'hôtels insolites au Luxembourg avec Cocobino. Vivez une expérience unique et authentique dans ce joyau européen." />
            <ScrollToTop />
            <Header2 />
            <PromotionBanner
                title={"💖 Offrez une Saint-Valentin inoubliable 💖"}
                message={messageBanner}
                displayBtn={false}
                btnLink={'/boutique'}
            />
            <Snackbar
                open={open}
                autoHideDuration={4000}  // La durée avant que l'alerte ne disparaisse (en millisecondes)
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Position de l'alerte
            >
                <Alert onClose={handleClose} severity="info">Veuillez mettre des dates</Alert>
            </Snackbar>
            <section className="container7">
                <div className="container7-1">
                    <div className="BackgroundPack">
                        <div className="backgroundDarken">
                            <div className="TitrePack"><h1>Découvrez les Meilleurs Hôtels Insolites au Luxembourg</h1></div>
                            <div className="DescriptionPack"><p>Explorez l'exception avec Cocobino : meilleurs hôtels insolites, voyages uniques au Luxembourg. Vivez une expérience inoubliable dans ce joyau européen. Le Luxembourg à tellement de choses à offrir et notre mission, c'est de vous les faire découvrir !</p></div>

                        </div>
                    </div>
                </div>
            </section>
            <section className='sectionBoxDeVoyage'>
                <div className='sectionBoxDeVoyage__PageViewContainer'>
                    {
                        COCOBINO_HOTELS.hotels.map((hotel_db, key) => {
                            return (
                                <BoxDeVoyage
                                    hotel={hotel_db}
                                    key={key}
                                    displayOffer={true}
                                />
                            );
                        })
                    }
                </div>
            </section>

            <Footer />
        </div>
    );
};


export default Boutique;