import React from 'react';
import '../styles/pages_style/ConditionsUtilisation.css'; // Fichier CSS pour la mise en forme
import Footer from '../components/Footer';
import Header2 from '../components/Header2';

const ConditionsUtilisation = () => {
  document.title = "Condition d'utilisation de cocobino";
  return (
    <>
    <Header2 />
    <div className="conditions-container">
      <h1 className="main-title">Conditions d'utilisation</h1>
      
      <section className="section">
        <h2 className="section-title">1. Introduction</h2>
        <p className="section-paragraph">
          Bienvenue sur notre plateforme. En utilisant nos services, vous acceptez les termes et conditions décrits dans ce document. Veuillez les lire attentivement.
        </p>
      </section>

      <section className="section">
        <h2 className="section-title">2. Rôle de notre service</h2>
        <p className="section-paragraph">
          Nous agissons en tant qu'intermédiaire pour vous offrir une expérience personnalisée et pratique. Toutefois, nous ne garantissons pas la qualité des services tiers proposés.
        </p>
      </section>

      <section className="section">
        <h2 className="section-title">3. Responsabilités de l'utilisateur</h2>
        <p className="section-paragraph">
          Vous êtes tenu de respecter toutes les lois applicables et d'utiliser notre plateforme de manière appropriée. Tout comportement abusif peut entraîner la suspension de votre accès.
        </p>
      </section>

      <section className="section">
        <h2 className="section-title">4. Modifications des conditions</h2>
        <p className="section-paragraph">
          Nous nous réservons le droit de modifier ces conditions à tout moment. Les mises à jour seront publiées sur cette page et entreront en vigueur immédiatement.
        </p>
      </section>
    </div>
    <Footer />
    </>
  );
};

export default ConditionsUtilisation;