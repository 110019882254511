import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { TextField, Button } from '@mui/material'
import '../styles/components_style/TravelPlanner.scss';
import Alert from './Alert';
import { useTravel } from '../context/TravelCartContext';
//import { format } from "date-fns";

const TravelPlannerV2 = () => {
    const { setDateA, setDateD } = useTravel();

    const { register, formState: { errors }, handleSubmit, watch } = useForm();
    const [alertState, setAlertState] = useState(false);
    const [dateValidate, setDateValidate] = useState(false);

    const arrivalDate = watch("arrivalDate");
    const departureDate = watch("departureDate");

    useEffect(() => {
        // Reset departureDate if it is before arrivalDate
        if (arrivalDate && departureDate && new Date(departureDate) < new Date(arrivalDate)) {
            // Reset departure date to empty if it's invalid
            setDateD("");
        }
    }, [arrivalDate, departureDate, setDateD]);

    const onSubmit = (data) => {
        if (new Date(data.arrivalDate) >= new Date(data.departureDate)) {
            setAlertState(true);
            return;
        }
        //const arrivalDate = format(data.arrivalDate, "dd/MM/yyyy");
        //const departureDate = format(data.departureDate, "dd/MM/yyyy");
        const arrivalDate = data.arrivalDate;
        const departureDate = data.departureDate;        
        setDateA(arrivalDate);
        setDateD(departureDate);
        setDateValidate(true);
    };

    return (
        <>
            <Alert message={"La date d'arrivée doit être antérieure à la date de départ."} setOpenFunction={setAlertState} openState={alertState} />
            <div className="navbarTravelPlanner">
                <div className='navbarTravelPlanner__container'>
                    <TextField
                        error={!!errors.arrivalDate}
                        label={errors.arrivalDate ? "Date d'arrivé requise !" : "Date d'arrivé"}
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        style={{ paddingLeft: "10px" }}
                        {...register("arrivalDate", { required: "Ce champ est requis" })}
                    />
                    <TextField
                        error={!!errors.departureDate || (arrivalDate && departureDate && new Date(departureDate) < new Date(arrivalDate))}
                        label={errors.departureDate ? "Date de départ requise !" : "Date de départ"}
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        style={{ paddingLeft: "10px" }}
                        {...register("departureDate", { required: "Ce champ est requis" })}
                    />

                    <div className='navbarTravelPlanner__container__finalInputs'>
                        <Button
                            variant="contained"
                            style={{ width: "100%", height: "100%", backgroundColor: dateValidate ? "green" : "black" }}
                            onClick={handleSubmit(onSubmit)}
                        >
                            {dateValidate ? "Dates ok" : "Valider"}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TravelPlannerV2;