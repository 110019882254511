import React, { useState } from 'react';
import ShoppingCart from '../../components/newComponents/ShoppingCart';
import ActivityBubble from '../../components/newComponents/ActivityBubble';
import Button from '@mui/material/Button';
import RoomBouble from '../../components/newComponents/RoomBouble';
import { TravelInformation } from '../../components/newComponents/TravelInformation';
import "../../styles/pages_style/TravelPage.scss";
import Header2 from '../../components/Header2';
import Footer from '../../components/Footer';
import { useParams } from 'react-router-dom';
import Alert from '../../components/Alert';
import ScrollToTop from '../../components/ScrollTop';
import SupplementBubble from '../../components/newComponents/SupplementBubble';
import SUPP from '../supp.json';
import { useTravel } from '../../context/TravelCartContext';
import COCOBINO_ACTIVITY from "../../local_db/db.json";

const TravelPage = () => {

    const { howManyNights, hotelForTravelPage, getReservationPage } = useTravel();
    const { hotel_title } = useParams();
    const [openAlert, setOpenAlert] = useState(false);

    document.title = `Page Hotel ${hotel_title}`;

    let supp = SUPP;

    return (
        <>
            <Header2 />
            <ScrollToTop />
            <Alert message={"votre panier est vide"} openState={openAlert} setOpenFunction={setOpenAlert} />
            <div className='connexionPage'>
                <ShoppingCart />
                <TravelInformation hotelTitle={hotel_title} testImagesArrays={hotelForTravelPage.imgUrlArray} testHotelDescription={hotelForTravelPage.description} testRoomAdvantage={hotelForTravelPage.advantages}
                    hotelLocation={hotelForTravelPage.location} />
                    
                <div className='title_headSection'>
                    <h3>Chambres disponibles</h3>
                    <p>Découvrez des chambres soigneusement choisies pour leur charme, leur intimité et leur ambience romantique. Chaque détail est pensé por créer des moments uniques à deux, dans un cadre élégant et mémorable</p>
                </div>
                <div className='roomList'>
                    {hotelForTravelPage.rooms.map((room, index) => {
                        return (
                            <RoomBouble
                                roomTitle={room.titleRoom}
                                roomImg={room.imgUrlArrayRoom}
                                roomAdvantages={room.advantageRoom}
                                roomPrice={room.price}
                                roomDescription={room.descriptionRoom}
                                roomMaxOfPerson={room.maxOfPerson}
                                key={index}
                                nights={howManyNights}
                            />
                        )
                    })}
                </div>
                <div className='title_headSection'>
                    <h3>Les activités</h3>
                    <p>Profitez d'activité soigneusement choisies et organisées avec le plus grand soin, spécialement fait pour les couples. Chaque expérience est pensée pour renforcer votre complicité et créer des souvenir uniques</p>
                </div>
                <div className='roomList'>
                    {
                        COCOBINO_ACTIVITY.activity.map((activty, key) => {
                            return hotelForTravelPage.localite === activty.localite || activty.type === "activity_coco" ? (
                                <ActivityBubble
                                    activityTitle={activty.title}
                                    activityImgUrlArr={activty.imgUrlArray}
                                    activityDescription={activty.description}
                                    activityAdvantageArr={activty.advantages}
                                    activityLocation={activty.location}
                                    activityPriceAdulte={activty.prixAdulte}
                                    activityPriceEnfant={activty.prixEnfant}
                                    key={key}
                                    activityType={activty.type}
                                />
                            ) : null
                        })
                    }
                </div>
                <div className='title_headSection'>
                    <h3>Les Suppléments</h3>
                    <p>Ajouter une touche d'exception à votre voyage avec nos Suppléments exclusifs : décore romantique, douceurs gourmandes, cadeaux surprise et accès VIP pour une expérience romantique et inoubliable</p>
                </div>
                <div className='suppList'>
                    {supp.map((elmnt, key) => {
                        return (
                            <SupplementBubble
                                packTitle={elmnt.titre}
                                packageDescription={elmnt.grandDescription}
                                packageImg={elmnt.img}
                                key={key}
                                packagePrice={elmnt.prix}
                                packageAVTDescription={elmnt.description}
                                displayPromotion={elmnt.displayOffer}
                            />
                        )
                    })}
                </div>
                <div className='btnContainerEndOfPage'  >
                    <Button variant="contained" onClick={()=>getReservationPage()} style={{ 'backgroundColor': 'black', 'boxShadow': 'none', 'width': '100%', marginTop: "35px" }}>passer à la réservation du voyage</Button>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default TravelPage;