import React from 'react';
import "../styles/pages_style/ThankyouPage.scss";
import Header2 from '../components/Header2';
import instaLogo from '../styles/assets/icons/instagramLogo.svg';

/**
 * landing page avec message, + lien vers notre instagram 
 * texte au milieu centré 
 * @returns 
 */

const Remerciement = () => {
    document.title = "Page de remerciement";
    return (
        <>
            <Header2 />
            <div className='thankYouPage_pageView'>
                <div className="thankYouPage_pageView__elmntContainer">
                    <h1>Félicitation votre email à été envoyé avec succées</h1>
                    <p style={{ marginTop: "20px" }}>
                        Votre voyage est cours de préparation vous recevrez
                        bientot une email de notre part pour confirmer votre séjour
                    </p>
                    <div className='thankYouPage_pageView__socialContainer'>
                        <div className='thankYouPage_pageView__socialContainer__textContainer'>
                            <p>En attendant votre réponse n'hésitez pas à découvrir notre contenu</p>
                        </div>
                        <div className='thankYouPage_pageView__socialContainer__instaIconContainer'>
                            <img src={instaLogo} alt="" className='thankYouPage_pageView__socialContainer__instaIconContainer__icon' onClick={()=>window.location.href = 'https://www.instagram.com/cocobino/'}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Remerciement;