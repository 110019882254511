import React, { useState, useRef, useEffect } from 'react';
import BoxDeVoyage from './BoxDeVoyage';
import "../styles/components_style/TravelSlideShow.scss";
import ButtonSimple from './ButtonSimple';
import COCOBINO_HOTELS from "../local_db/db.json";
import { useNavigate } from 'react-router-dom';

const TravelSlideShow = () => {

    const navigate = useNavigate();
    const sliderWRef = useRef(null);
    const travelBoxRef = useRef(null);
    const [numberOfBoxesToDisplay, setNumberOfBoxesToDisplay] = useState(null);
    
    useEffect(()=>{
        const updateNumberOfBoxesToDisplay = () => {
            if (sliderWRef.current && travelBoxRef.current) {
                const sliderWidth = sliderWRef.current.offsetWidth;
                const travelBoxWidth = travelBoxRef.current.offsetWidth;
                if (travelBoxWidth > 0) { // Éviter la division par zéro
                    setNumberOfBoxesToDisplay(Math.floor(sliderWidth / travelBoxWidth));
                }
            } else {
                const sliderWidth = sliderWRef.current.offsetWidth;
                const travelBoxWidth = 280;
                if (travelBoxWidth > 0) { // Éviter la division par zéro
                    setNumberOfBoxesToDisplay(Math.floor(sliderWidth / travelBoxWidth));
                }
            }
        };
    
        // Effectuer le calcul après le premier rendu
        updateNumberOfBoxesToDisplay();
    
        // Ajouter un écouteur d'événement pour les changements de taille de fenêtre
        window.addEventListener('resize', updateNumberOfBoxesToDisplay);
    
        // Nettoyer les écouteurs d'événements
        return () => window.removeEventListener('resize', updateNumberOfBoxesToDisplay);
    },[]);

    function handleClick() {
        navigate('/boutique');
    }

    return (
        <>
            <div className='SlideShow'>
                <div className="SlideShow__travelContainer" ref={sliderWRef}>
                    <div className="SlideShow__packs" >
                        {
                            COCOBINO_HOTELS.hotels.slice(0,numberOfBoxesToDisplay).map((hotel_db, key) => {
                                return (
                                    <BoxDeVoyage
                                        hotel={hotel_db}
                                        key={key}
                                        refBox={travelBoxRef}
                                        displayOffer={true}
                                    />
                                );
                            })
                        }
                    </div>
                </div>
                <div className='SlideShow__buttonContainer'>
                    <ButtonSimple label={"Explorer tout nos voyages"} number={1} onClickFunction={()=>handleClick()}/>
                </div>
            </div>
        </>
    );

}

export default TravelSlideShow;
