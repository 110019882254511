import React from 'react';
import ButtonClassic from '../components/ButtonClassic';
//import pinterestLogo from '../styles/assets/icons/pinterest-svgrepo-com.svg';

const HomePage = () => {
    
    /*const handleFacebookIconClick = () => {
        window.location.href = 'https://www.pinterest.com/cocobinoc/';
    };*/

    const handleInstaIconClick = () => {
        window.location.href = 'https://www.instagram.com/cocobino/';
    }

    return (
        <section className='container1'>
            <div className="blocHaut1-1">
                <div className="blocTexte1-1">
                    <div className="titreC1-1">
                        <h1 id='mainTitle'>Vivez l'amour comme jamais avec Cocobino</h1>
                    </div>
                    <div className="presentationC1-1">
                        <p>Votre expert en voyages romantiques au Luxembourg, où chaque détail est soigneusement conçu pour vous offrir une expérience unique. 
                            Chaque escapade est sublimée par un service de qualité et des avantages exclusifs Coco.  Laissez-nous créer ensemble le voyage de rêve 
                            qui honorera votre histoire d'amour.<strong>Réservez maintenant !</strong></p>
                    </div>
                    <div className="blocBouton1-1">
                        <ButtonClassic 
                            name={"Réservez maintenant"}
                            number={1}
                            newPage={'/boutique'}
                        />
                        <ButtonClassic
                            name={"Qui sommes nous"}
                            number={2}
                            newPage={'/about'}
                        />
                    </div>
                </div>
                <div className="blocReseau1-1">
                        {/*<div className="imageFB" onClick={handleFacebookIconClick}>
                            <i className="fa-brands fa-facebook-f">
                            </i> 
                        </div>*/}
                        
                        <div className="imageInstagram" onClick={handleInstaIconClick}>
                            <i className="fa-brands fa-instagram"></i>

                        </div>
                    </div>
            </div>
            <div className="blocBas1-1">
                    <div className="blocReseauBas1-1">
                    </div>
                        <div className="blocSwap1-1">
                            <div className="boutonSwap"></div>
                            <div className="boutonSwap"></div>
                            <div className="boutonSwap"></div>
                            <div className="boutonSwap"></div>
                        </div>
                    <div className="blocReseauBas1-1">
                        <div className="imageInstagram" onClick={handleInstaIconClick}><i className="fa-brands fa-instagram"></i></div>
                    </div>
                </div>
        </section>
    );
};

export default HomePage;