import React from "react";
import '../styles/pages_style/ConditionsUtilisation.css'; 
import Header2 from "../components/Header2";

const ConditionsVente = () => {
  document.title = 'Condition de vente de cocobino';
  return (
    <>
    <Header2 />
    <div className="conditions-container">
      <h1 className="main-title">Conditions de Vente</h1>

      <div className="section">
        <h2 className="section-title">Rôle d'intermédiaire</h2>
        <p className="section-paragraph">
          Cocobino agit uniquement en tant qu'intermédiaire pour la réservation d'hébergements,
          d'activités (hors activités Cocobino) et de services proposés par des partenaires. Cocobino
          ne vend pas directement ces services.
        </p>
      </div>

      <div className="section">
        <h2 className="section-title">Limitation de responsabilité</h2>
        <p className="section-paragraph">
          Cocobino n'est pas responsable de l'expérience du client une fois sur place dans l'hôtel ou
          lors des activités réservées via notre plateforme, à l'exception des activités organisées
          directement par Cocobino.
        </p>
      </div>

      <div className="section">
        <h2 className="section-title">Politique de remboursement</h2>
        <p className="section-paragraph">
          Les remboursements ne sont pas garantis, mais Cocobino s'engage à fournir un support pour
          résoudre tout problème lié aux réservations effectuées via notre plateforme.
        </p>
      </div>

      <div className="section">
        <h2 className="section-title">Précision des informations</h2>
        <p className="section-paragraph">
          Cocobino collecte les informations auprès des prestataires de services et s'efforce de
          fournir des détails précis et actualisés. Cependant, nous ne garantissons pas l'exactitude
          absolue des informations.
        </p>
      </div>

      <div className="section">
        <h2 className="section-title">Paiements et transactions</h2>
        <p className="section-paragraph">
          Si Cocobino organise votre paiement, nous (ou notre affilié) serons responsables de la
          gestion de votre paiement et de la réalisation de la transaction avec le prestataire de
          services. En cas de comportement frauduleux ou d'utilisation non autorisée de votre moyen
          de paiement, veuillez nous contacter immédiatement.
        </p>
      </div>

      <div className="section">
        <h2 className="section-title">Acceptation des conditions</h2>
        <p className="section-paragraph">
          En effectuant une réservation, vous acceptez les conditions applicables affichées lors du
          processus de réservation. Certaines réservations ne peuvent pas être annulées gratuitement
          ou sont soumises à une date limite d'annulation gratuite.
        </p>
      </div>

      <div className="section">
        <h2 className="section-title">Tarifs</h2>
        <p className="section-paragraph">
          Les tarifs affichés incluent tous les frais applicables et taxes. Certains tarifs peuvent
          avoir été arrondis pour faciliter leur présentation.
        </p>
      </div>

      <div className="section">
        <h2 className="section-title">Comportement attendu</h2>
        <p className="section-paragraph">
          Prenez soin de l’hébergement ainsi que des meubles, des équipements, des appareils
          électroniques et autres éléments, et laissez les lieux dans le même état que celui dans
          lequel vous les avez trouvés en arrivant. Si un objet est cassé, endommagé ou perdu,
          assurez-vous de le signaler au personnel sur place.
        </p>
      </div>
    </div>
    </>
  );
};

export default ConditionsVente;
