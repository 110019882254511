import React from 'react';
import HomePage from "../pages/HomePage"
import HomePageThird from "./HomePageThird";
import Footer from "../components/Footer";
import HomePageSecond from "./HomePageSecond";
import Header2 from "../components/Header2";
import ScrollToTop from "../components/ScrollTop";
import PromotionBanner from '../components/newComponents/PromotionBanner';

const Home = () => {
    document.title = 'cocobino - Un voyage au Luxembourg personnalisé et authentique';

    const messageBanner = (
        <>
            Surprenez votre moitié avec <strong>champagne</strong>, <strong>vin</strong> et <strong>un Pack Romance</strong> offert !
        </>
    );

    return (
        <div>
            <ScrollToTop />
            <Header2 />
            <PromotionBanner
                title={"💖 Offrez une Saint-Valentin inoubliable 💖"}
                message={messageBanner}
                displayBtn={true}
                btnLink={'/boutique'}
            />
            <HomePage />
            <HomePageThird sectionElement={<HomePageSecond />} />
            <Footer />
        </div>
    );
};

export default Home;
