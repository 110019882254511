import React from 'react';
import '../styles/pages_style/ConditionsUtilisation.css';
import Header2 from '../components/Header2';

const PolitiqueConfidentialite = () => {
    document.title = 'Politique de confidentialité de cocobino';
    return (
        <>
            <Header2 />
            <div className="conditions-container">
                <h1 className="main-title">Politique de Confidentialité</h1>

                <section className="section">
                    <h2 className="section-title">Introduction</h2>
                    <p className="section-paragraph">
                        Chez Cocobino, nous accordons une grande importance à la protection de vos données personnelles. Cette
                        politique de confidentialité décrit les types d'informations que nous collectons, comment elles sont
                        utilisées et les mesures prises pour assurer leur sécurité.
                    </p>
                </section>

                <section className="section">
                    <h2 className="section-title">Collecte des Données</h2>
                    <p className="section-paragraph">
                        Nous collectons uniquement les données nécessaires pour fournir nos services, telles que votre nom, vos
                        coordonnées, vos préférences de voyage et les informations de paiement lorsque cela est requis.
                    </p>
                </section>

                <section className="section">
                    <h2 className="section-title">Utilisation des Données</h2>
                    <p className="section-paragraph">
                        Vos données sont utilisées pour personnaliser vos expériences de voyage, gérer vos réservations et vous
                        fournir un service client de qualité. Nous pouvons également utiliser vos informations pour vous envoyer
                        des offres spéciales ou des mises à jour importantes sur nos services.
                    </p>
                </section>

                <section className="section">
                    <h2 className="section-title">Partage des Données</h2>
                    <p className="section-paragraph">
                        Vos données ne seront partagées qu'avec des partenaires de confiance nécessaires à la prestation de nos
                        services, tels que les hôtels et restaurants. Nous ne vendons jamais vos informations personnelles à des
                        tiers.
                    </p>
                </section>

                <section className="section">
                    <h2 className="section-title">Sécurité des Données</h2>
                    <p className="section-paragraph">
                        Nous mettons en œuvre des mesures techniques et organisationnelles robustes pour protéger vos données contre
                        tout accès non autorisé, perte ou divulgation.
                    </p>
                </section>

                <section className="section">
                    <h2 className="section-title">Vos Droits</h2>
                    <p className="section-paragraph">
                        Vous avez le droit d'accéder à vos données personnelles, de demander leur rectification ou suppression, et
                        de vous opposer à leur traitement dans certaines conditions. Pour exercer vos droits, veuillez nous
                        contacter à cocobinocontact@gmail.com
                    </p>
                </section>

                <section className="section">
                    <h2 className="section-title">Modifications</h2>
                    <p className="section-paragraph">
                        Cette politique de confidentialité peut être mise à jour périodiquement. Nous vous recommandons de la
                        consulter régulièrement pour rester informé des éventuels changements.
                    </p>
                </section>
            </div>
        </>

    );
};

export default PolitiqueConfidentialite;
